<template>
  <component
    :is="tag"
    class="ucsc-navbar__item"
    role="none"
    aria-haspopup="true"
    aria-expanded="false"
    :class="[
      additionalClasses,
      {
        'ucsc-navbar__item--hasSubnav': item.children,
        'ucsc-navbar__item--isActive': itemIsActive,
      },
    ]"
  >
    <NuxtLink
      ref="menuitem"
      class="ucsc-navbar__link"
      :to="item.href"
      role="menuitem"
      tabindex="0"
    >
      <span v-if="item.icon" class="ucsc-navbar__iconWrapper">
        <SvgIcon
          :name="item.iconheader"
          :class="[
            'ucsc-navbar__icon',
            {
              'ucsc-navbar__icon--active': itemIsActive,
            },
          ]"
        />
      </span>
      <span
        v-dompurify-html="item.text"
        class="ucsc-navbar__label"
        :class="{ 'ucsc-navbar__label--isActive': itemIsActive }"
      ></span>
    </NuxtLink>
    <ul
      v-if="item.children"
      ref="submenu"
      class="ucsc-navbar__subnav"
      :class="{ 'ucsc-navbar__subnav--isVisible': subLinkActiveIndex !== -1 }"
      role="menu"
      :aria-label="item.text"
    >
      <template v-for="(subItem, idx) in item.children">
        <li
          v-if="!subItem.onlyMobile"
          :key="idx"
          class="ucsc-navbar__subItem"
          role="none"
        >
          <NuxtLink
            :class="[
              'ucsc-navbar__subLink',
              {
                'ucsc-navbar__subLink--isActive': subLinkActiveIndex === idx,
              },
            ]"
            :to="subItem.href"
            role="menuitem"
            @focus.native="handleMenuFocus"
          >
            {{ subItem.text }}
          </NuxtLink>
        </li>
      </template>
    </ul>
  </component>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return null
      },
    },
    tag: {
      type: String,
      default: 'div',
    },
    additionalClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyboardFocus: true,
    }
  },
  computed: {
    itemIsActive() {
      const { fullPath } = this.$route
      const { href } = this.item

      const pathHref = fullPath.split('/')[1]
      const itemHref = href.replace('/', '')
      return pathHref === itemHref || this.subLinkActiveIndex !== -1
    },

    subLinkActiveIndex() {
      const { fullPath } = this.$route
      const { children } = this.item

      if (!children) {
        return -1
      }

      const subLinkActiveIndex = children.findIndex((it) => {
        const regex = it.href.replace('/', '\\/')

        switch (true) {
          case it.onlyMobile:
          case !fullPath.match(regex):
            return false
        }

        return true
      })

      return subLinkActiveIndex
    },
  },
  mounted() {
    const menuItem = this.$refs.menuitem

    menuItem.$el.addEventListener('focus', this.handleMenuFocus)
    menuItem.$el.addEventListener('keydown', () => {
      this.keyboardFocus = true
    })
    menuItem.$el.addEventListener('keyup', () => {
      this.keyboardFocus = true
    })
    menuItem.$el.addEventListener('mousedown', () => {
      this.keyboardFocus = false
    })
  },

  methods: {
    closeAllSubmenus() {
      document
        .querySelectorAll('.ucsc-navbar__subnav--isFocused')
        .forEach((submenu) => {
          submenu?.classList.remove('ucsc-navbar__subnav--isFocused')
        })
    },

    handleMenuFocus() {
      const subMenu = this.$refs.submenu

      this.closeAllSubmenus()
      if (this.keyboardFocus) {
        subMenu?.classList.add('ucsc-navbar__subnav--isFocused')
      }
    },
  },
}
</script>
