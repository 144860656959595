var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"ucsc-navbar__item",class:[
    _vm.additionalClasses,
    {
      'ucsc-navbar__item--hasSubnav': _vm.item.children,
      'ucsc-navbar__item--isActive': _vm.itemIsActive,
    },
  ],attrs:{"role":"none","aria-haspopup":"true","aria-expanded":"false"}},[_c('NuxtLink',{ref:"menuitem",staticClass:"ucsc-navbar__link",attrs:{"to":_vm.item.href,"role":"menuitem","tabindex":"0"}},[(_vm.item.icon)?_c('span',{staticClass:"ucsc-navbar__iconWrapper"},[_c('SvgIcon',{class:[
          'ucsc-navbar__icon',
          {
            'ucsc-navbar__icon--active': _vm.itemIsActive,
          },
        ],attrs:{"name":_vm.item.iconheader}})],1):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.item.text),expression:"item.text"}],staticClass:"ucsc-navbar__label",class:{ 'ucsc-navbar__label--isActive': _vm.itemIsActive }})]),_vm._v(" "),(_vm.item.children)?_c('ul',{ref:"submenu",staticClass:"ucsc-navbar__subnav",class:{ 'ucsc-navbar__subnav--isVisible': _vm.subLinkActiveIndex !== -1 },attrs:{"role":"menu","aria-label":_vm.item.text}},[_vm._l((_vm.item.children),function(subItem,idx){return [(!subItem.onlyMobile)?_c('li',{key:idx,staticClass:"ucsc-navbar__subItem",attrs:{"role":"none"}},[_c('NuxtLink',{class:[
            'ucsc-navbar__subLink',
            {
              'ucsc-navbar__subLink--isActive': _vm.subLinkActiveIndex === idx,
            },
          ],attrs:{"to":subItem.href,"role":"menuitem"},nativeOn:{"focus":function($event){return _vm.handleMenuFocus.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(subItem.text)+"\n        ")])],1):_vm._e()]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }